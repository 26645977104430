/**
 * Common functionality.
 */
define(
    'common/main',[
        "angular",
        "./services/pageScroll",
        "./services/playRoutes",
        "./filters",
        "./directives/scrollable",
        "./directives/fileModel",
        "./venn" // todo: further integrate
    ],
    function (angular) {
        "use strict";

        return angular.module("narthex.common", [
            "common.pageScroll",
            "common.playRoutes",
            "common.filters",
            "common.directives.scrollable",
            "common.directives.fileModel"
        ]);
    }
);

define('common', ['common/main'], function (main) { return main; });

