/** Common filters. */
define('common/filters',["angular"], function (angular) {
    "use strict";

    var mod = angular.module("common.filters", []);
    /**
     * Extracts a given property from the value it is applied to.
     * {{{
   * (user | property:'name')
   * }}}
     */
    mod.filter("property", function (value, property) {
        if (angular.isObject(value)) {
            if (value.hasOwnProperty(property)) {
                return value[property];
            }
        }
    });


    mod.filter('unsafe', function($sce) {
        return function(val) {
            return $sce.trustAsHtml(val);
        };
    });

    return mod;
});

