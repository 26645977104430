/**
 * Play JavaScript routing as an AngularJS module.
 * Wraps Play's routes to use Angular's $http.
 * Example:
 * {{{
 * // For `POST /login controller.Application.login()` Play generates:
 * jsRoutes.controllers.Application.login()
 * // With playRoutes, this can be used like this:
 * playRoutes.controllers.Application.login().post({user:"username", password:"secret"}).then(function(response) {
 *   ...
 * )};
 * }}}
 * @author Marius Soutier, 2013
 */
define('common/services/playRoutes',["angular", "require", "jsRoutes"], function (angular, require, jsRoutes) {
    "use strict";

    // The service - will be used by controllers or other services, filters, etc.
    var mod = angular.module("common.playRoutes", []);

    mod.service("playRoutes", ["$http", function ($http) {
        /**
         * Wrap a Play JS function with a new function that adds the appropriate $http method.
         * Note that the url has been already applied to the $http method so you only have to pass in
         * the data (if any).
         * Note: This is not only easier on the eyes, but must be called in a separate function with its own
         * set of arguments, because otherwise JavaScript's function scope will bite us.
         * @param playFunction The function from Play's jsRouter to be wrapped
         */
        var wrapHttp = function (playFunction) {
            return function (/*arguments*/) {
                var routeObject = playFunction.apply(this, arguments);
                var httpMethod = routeObject.method.toLowerCase();
                var url = routeObject.url;
                var res = {
                    method: httpMethod,
                    url: url,
                    absoluteUrl: routeObject.absoluteURL,
                    webSocketUrl: routeObject.webSocketURL
                };
                res[httpMethod] = function (obj) {
                    return $http[httpMethod](url, obj);
                };
                return res;
            };
        };

        // Add package object, in most cases "controllers"
        var addPackageObject = function (packageName, service) {
            if (!(packageName in service)) {
                service[packageName] = {};
            }
        };

        // Add controller object, e.g. Application
        var addControllerObject = function (packageName, controllerKey, service) {
            if (!(controllerKey in service[packageName])) {
                service[packageName][controllerKey] = {};
            }
        };

        var playRoutes = {};
        // Loop over all items in the jsRoutes generated by Play, wrap and add them to playRoutes
        for (var packageKey in jsRoutes) {
            var packageObject = jsRoutes[packageKey];
            addPackageObject(packageKey, playRoutes);
            for (var controllerKey in packageObject) {
                var controller = packageObject[controllerKey];
                addControllerObject(packageKey, controllerKey, playRoutes);
                for (var controllerMethodKey in controller) {
                    playRoutes[packageKey][controllerKey][controllerMethodKey] =
                        wrapHttp(controller[controllerMethodKey]);
                }
            }
        }
        return playRoutes;
    }]);

    return mod;
});

